<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
      :headers="headers"
      :items="orders"
      :options.sync="pagination"
      :server-items-length="totalOrders"
      :loading="loading"
      show-expand
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1 class-on-data-table"
    >
      <template v-slot:item.totalSum="{ item }">
        {{ item.totalSum + "€" }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="padding: 0.5em 0.5em 1em 1em">
          <v-data-table
            dense
            :headers="orderItemHeaders"
            :items="item.orderItems"
            :disable-pagination="true"
            class="elevation-2"
            hide-default-footer
          >
            <template v-slot:item.finalPrice="{ item: orderItem }">
              <span class="old-price" v-if="orderItem.priceBeforeDiscount !== orderItem.finalPrice">
                {{ orderItem.priceBeforeDiscount + "€" }}
              </span>
              {{ orderItem.finalPrice + "€" }}
            </template>

            <template v-slot:item.discountInPercentage="{ item: orderItem }">
              {{ orderItem.discountInPercentage + "%" }}
            </template>
            <template v-slot:item.productPicture="{ item: orderItem }">
              <a v-bind:href="orderItem.pictureUrls.thumb_path3" target="_blank">
                <v-img
                  v-if="orderItem != null && orderItem.pictureUrls != null"
                  :src="orderItem.pictureUrls.thumb_path3"
                  contain
                  max-height="100"
                  max-width="100"
                >
                </v-img>
              </a>
            </template>
            <template v-slot:item.voucherPrice>
              {{ item.voucherPrice }} ({{ item.voucherPrice - item.voucherLeftover }})
            </template>
          </v-data-table>
        </td>
      </template>
      <template v-slot:item.created="{ item }">
        {{ parseDate(item.created) }}
      </template>
      <template v-slot:item.shopName="{ item }">
        <td class="text-xs-right">
          {{ item.shopDetails != null ? item.shopDetails.shopName : "" }}
        </td>
      </template>

      <template v-slot:item.orderInfo="{ item }">
        <v-icon color="teal" size="26" dark @click="goToOrderInfoDialog(item)"
          >mdi-information-outline
        </v-icon>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-text-field
              v-model="shopName"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveOrders();
              "
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="customerName"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveOrders();
              "
            ></v-text-field>
          </td>
          <td>
            <v-datetime-picker
              :label="$t('date.from')"
              v-model="dateFrom"
              time-format="HH:mm:ss"
            ></v-datetime-picker>
            <v-datetime-picker
              :label="$t('date.to')"
              v-model="dateTo"
              time-format="HH:mm:ss"
            ></v-datetime-picker>
          </td>
          <!--          <td>-->
          <!--            <v-spacer></v-spacer>-->
          <!--          </td>-->
          <!--          <td>-->
          <!--            <v-text-field-->
          <!--                v-model="discountFrom"-->
          <!--                hide-details-->
          <!--                single-line-->
          <!--                type="number"-->
          <!--            />-->
          <!--            <v-text-field-->
          <!--                v-model="discountTo"-->
          <!--                hide-details-->
          <!--                single-line-->
          <!--                type="number"-->
          <!--            />-->
          <!--          </td>-->
          <!--                    <td>-->
          <!--                      <v-text-field-->
          <!--                          v-model="totalPriceFrom"-->
          <!--                          hide-details-->
          <!--                          single-line-->
          <!--                          type="number"-->
          <!--                      />-->
          <!--                      <v-text-field-->
          <!--                          v-model="totalPriceTo"-->
          <!--                          hide-details-->
          <!--                          single-line-->
          <!--                          type="number"-->
          <!--                      />-->
          <!--                    </td>-->
          <!--          <td>-->
          <!--            <v-spacer></v-spacer>-->
          <!--          </td>-->
          <!--          <td>-->
          <!--            <v-spacer></v-spacer>-->
          <!--          </td>-->
          <!--          <td>-->
          <!--            <v-spacer></v-spacer>-->
          <!--          </td>-->
          <!--          <td>-->
          <!--            <v-text-field v-model="orderLocation" type="text"-->
          <!--                          @keyup.enter="page = 1;retrieveOrders();"></v-text-field>-->
          <!--          </td>-->
          <!--          <td>-->
          <!--            <v-text-field-->
          <!--                v-model="totalPriceFrom"-->
          <!--                hide-details-->
          <!--                single-line-->
          <!--                type="number"-->
          <!--            />-->
          <!--            <v-text-field-->
          <!--                v-model="totalPriceTo"-->
          <!--                hide-details-->
          <!--                single-line-->
          <!--                type="number"-->
          <!--            />-->
          <!--          </td>-->
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-row>
              <v-select
                :items="orderStatusList"
                v-model="orderStatus"
                dense
                hide-details
                @keyup.enter="
                  page = 1;
                  retrieveOrders();
                "
              ></v-select>
              <v-col></v-col>

              <v-col>
                <v-avatar color="indigo" size="30">
                  <v-icon
                    dark
                    @click="
                      page = 1;
                      retrieveOrders();
                    "
                    >mdi-magnify
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col>
                <v-avatar color="grey" size="30">
                  <v-icon
                    dark
                    @click="
                      page = 1;
                      clearSearch();
                    "
                    >mdi-close
                  </v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="table-footer-prepend d-flex pl-2 align-center">
      <v-btn
        :loading="downloadingReport"
        :disabled="downloadingReport"
        color="green darken-1"
        class="ma-2 white--text"
        @click="
          loader = 'downloadingReport';
          downloadReport();
        "
      >
        {{ $t("pages.orders.or") }}
        <v-icon right dark> mdi-file-excel </v-icon>
      </v-btn>
      <v-btn
        :loading="downloadingItemsReport"
        :disabled="downloadingItemsReport"
        color="green darken-1"
        class="ma-2 white--text"
        @click="
          loader = 'downloadingItemsReport';
          downloadItemsReport();
        "
      >
        {{ $t("pages.orders.oir") }}
        <v-icon right dark> mdi-file-excel </v-icon>
      </v-btn>
    </div>
    <ProductDialog
      v-model="showProductDialog"
      @close="showProductDialog = false"
      :shopProduct="shopProduct"
    />
    <OrderInfoDialog
      v-model="showOrderInfoDialog"
      @close="showOrderInfoDialog = false"
      :orderInfo="orderInfo"
    />
    <GalleryDialog
      v-model="showGalleryDialog"
      @close="showGalleryDialog = false"
      :mediaList="mediaList"
    />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductDialog from "@/view/pages/ProductDialog";
import OrderInfoDialog from "@/view/pages/OrderInfoDialog";
import GalleryDialog from "@/view/pages/GalleryDialog";
import moment from "moment";
import OrderingApiService from "@/core/services/api.service.ordering";
import { mapGetters } from "vuex";

export default {
  name: "orders",
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("menu.orders");
    },
    headers() {
      return [
        {
          text: this.$i18n.t("pages.orders.orderNumber"),
          align: "start",
          sortable: false,
          value: "orderOrdinalNumber"
        },
        {
          text: this.$i18n.t("commonTable.shopName"),
          value: "shopName",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.orders.customerName"),
          value: "userDetails.name",
          sortable: false
        },
        {
          text: this.$i18n.t("commonTable.date"),
          value: "created",
          width: "10%",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.orders.totalPrice"),
          value: "totalSum",
          sortable: false
        },
        {
          text: this.$i18n.t("commonTable.status"),
          value: "orderStatus",
          sortable: false
        }
      ];
    },
    orderItemHeaders() {
      return [
        {
          text: this.$i18n.t("commonTable.productName"),
          value: "name",
          sortable: false
        },
        {
          text: this.$i18n.t("commonTable.productPicture"),
          value: "productPicture",
          sortable: false
        },
        {
          text: this.$i18n.t("commonTable.quantity"),
          value: "quantity",
          sortable: false
        },
        {
          text: this.$i18n.t("commonTable.productDiscount"),
          value: "discountInPercentage",
          sortable: false
        },
        {
          text: this.$i18n.t("commonTable.finalPrice"),
          value: "finalPrice",
          sortable: false
        },
        {
          text: this.$i18n.t("commonTable.voucherPrice"),
          value: "voucherPrice",
          sortable: false
        }
      ];
    }
  },
  data() {
    return {
      orderingApiInitiated: false,
      dates: [],
      dateRangeMenu: false,
      dateFrom: null,
      dateTo: null,
      shopName: "",
      customerName: "",
      customerEmail: "",
      orderLocation: "",
      orderStatus: "",
      orderStatusList: [
        "WAITING_FOR_CONFIRMATION",
        "WAITING_FOR_GROUP_SHOPPING",
        "CONFIRMED",
        "REJECTED",
        "RESOLVED",
        "IN_DISPUTE",
        "COMPLETED"
      ],
      downloadingReport: false,
      downloadingItemsReport: false,
      status: "",
      totalOrders: 0,
      orders: [],
      loading: true,
      pagination: {},
      showProductDialog: false,
      shopProduct: null,
      showOrderInfoDialog: false,
      orderInfo: null,
      showGalleryDialog: false,
      mediaList: [],
      discountFrom: 0,
      discountTo: 99,
      priceFrom: null,
      priceTo: null,
      totalPriceFrom: null,
      totalPriceTo: null,
      errors: []
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.orderingApiInitiated) this.retrieveOrders();
      },
      deep: true
    }
  },
  async created() {
    if (!this.currentUser.superAdmin) return;
    this.loading = true;
    this.$log.debug("await init ordering service - start");
    await OrderingApiService.init();
    this.$log.debug("await init ordering service - end");
    this.orderingApiInitiated = true;
    this.retrieveOrders();
  },
  components: {
    ProductDialog,
    OrderInfoDialog,
    GalleryDialog
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
  },
  methods: {
    downloadReport() {
      this.downloadingReport = true;
      const params = this.getRequestParams(
        this.shopName,
        this.customerName,
        this.customerEmail,
        this.dateFrom,
        this.dateTo,
        this.orderLocation,
        this.orderStatus,
        this.discountFrom,
        this.discountTo,
        this.priceFrom,
        this.priceTo,
        this.totalPriceFrom,
        this.totalPriceTo
      );
      params.responseType = "blob";
      ApiService.getFileWithParams(`/api/download/excel/orders-report`, params)
        .then(response => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Orders";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.downloadingReport = false));
    },
    downloadItemsReport() {
      this.downloadingItemsReport = true;
      const params = this.getRequestParams(
        this.shopName,
        this.customerName,
        this.dateFrom,
        this.dateTo,
        this.orderStatus
      );
      params.responseType = "blob";
      ApiService.getFileWithParams(`/api/download/excel/orders-items-report`, params)
        .then(response => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "OrdersItems";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.downloadingItemsReport = false));
    },
    parseDate(date) {
      if (date == null) return "";
      return moment
        .utc(date)
        .local()
        .format("yyyy-MM-DD HH:mm:ss");
    },
    goToGallery(mediaList) {
      this.showGalleryDialog = true;
      this.mediaList = mediaList;
    },
    goToProductDialog(shopProduct) {
      this.showProductDialog = true;
      this.shopProduct = shopProduct;
    },
    goToOrderInfoDialog(item) {
      this.showOrderInfoDialog = true;
      this.orderInfo = item;
    },
    getRequestParams(shopName, customerName, dateFrom, dateTo, status) {
      let params = {};
      if (shopName) {
        params["ShopName"] = shopName;
      }
      if (customerName) {
        params["FullName"] = customerName;
      }
      if (dateFrom) {
        params["DateFrom"] = dateFrom;
      }

      if (dateTo) {
        params["DateTo"] = dateTo;
      }
      if (status) {
        params["OrderStatus"] = status;
      }
      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["Sort"] = sort;
      params["PageNumber"] = this.pagination.page - 1;
      params["PageSize"] = this.pagination.itemsPerPage;

      return params;
    },

    clearSearch() {
      (this.shopName = ""),
        (this.customerName = ""),
        (this.customerEmail = ""),
        (this.orderLocation = ""),
        (this.orderStatus = ""),
        (this.discountFrom = 5),
        (this.discountTo = 99),
        (this.priceFrom = null),
        (this.priceTo = null),
        (this.totalPriceFrom = null),
        (this.totalPriceTo = null);
    },
    retrieveOrders() {
      const params = this.getRequestParams(
        this.shopName,
        this.customerName,
        this.dateFrom,
        this.dateTo,
        this.orderStatus
      );

      return new Promise(resolve => {
        this.loading = true;
        OrderingApiService.query("/getorders/", params).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Orders: ", response.data.result);
          this.$log.debug("response.headers['x-pagination']: ", response.headers["x-pagination"]);
          this.orders = response.data.result;
          if (response.headers["x-pagination"] != null) {
            let xPaginationHeaders = JSON.parse(response.headers["x-pagination"]);
            this.totalOrders = xPaginationHeaders.totalCount;
            this.totalPages = xPaginationHeaders.totalPages;
          } else {
            this.totalOrders = undefined;
            this.totalPages = undefined;
          }
          this.loading = false;
          let title = this.totalOrders;
          this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` + title }]);
          resolve();
        });
      });
    }
  }
};
</script>
<style>
.old-price {
  text-decoration: line-through;
}

.v-data-table.v-data-table.v-data-table > td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

.old-price {
  text-decoration: line-through;
}

.v-data-table-header th {
  white-space: normal;
}

.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
}
</style>
